@font-face {
    font-family: 'DIN-Regular';
    font-style: normal;
    font-weight: 400;
    src: url('./ddin/DINR.TTF') format('truetype');
}
@font-face {
  font-family: 'DIN-Medium';
  font-style: medium;
  font-weight: 500;
  src: url('./ddin/DINM.TTF') format('truetype');
}
@font-face {
  font-family: 'DIN-Bold';
  font-style: bold;
  font-weight: 600;
  src: url('./ddin/DINBD.TTF') format('truetype');
}
@font-face {
  font-family: 'DIN-Bold';
  font-style: bold;
  font-weight: 800;
  src: url('./d_din_pro_2/d_din_pro/otf/D-DIN-PRO-800-ExtraBold.otf') format('opentype');
}